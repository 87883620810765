<template>
  <div class="mt-3" v-show="formIsOpen">
    <form @submit.prevent="formSubmit">
      <div class="md6">
        <va-input
          class="mb-4 mx-3"
          v-model="data.name"
          :label="$t('name')"
        />
                        
        <va-input
          class="mb-4 mx-3"
          v-model="data.name_ar"
          :label="$t('name_ar')"
        />
      </div>
      <div class="md6">
        <va-input
          class="mb-4 mx-3"
          v-model="data.descr"
          type="textarea"
          :label="$t('descr')"
        />
                        
        <va-input
          class="mb-4 mx-3"
          v-model="data.descr_ar"
          type="textarea"
          :label="$t('descr_ar')"
        />
      </div>
      <va-input
        class="mb-4 mx-3"
        v-model="data.img"
        type="file"
        :label="$t('subscription_fees')"
      />

      <va-divider inset />
                        
      <div class="card_footer">
        <va-button outline class="mr-4" type="submit">{{$t('save')}}</va-button>
        <va-button outline color="danger" class="mr-4" @click="closeForm">{{$t('cancel')}}</va-button>
      </div>

    </form>
  </div>
</template>

<script>
import { GraphQLClient } from 'graphql-request';
import { ACADEMY_SERVICES_INSERT } from '@/graphql/mutations/academy/services';
import { ACADEMY_SERVICES_UPDATE } from '@/graphql/mutations/academy/services';
export default {
    props: ["formIsOpen", "selectedRowToEdit"],
    data() {
        return {
            data: {},
        }
    },
    emits: ["closeForm", "rowInserted", "rowUpdated"],
    methods:{
        closeForm(){
            this.$emit('closeForm');
        },
        async formSubmit(){
            console.log(this.data);

            const graphQLClient = new GraphQLClient(this.$store.state.appUrl, {
                headers: {
                    // authorization: 'Bearer MY_TOKEN',
                },
            })

            const variables = {
                "name": this.data.name,
                "name_ar": this.data.name_ar,
                "descr": this.data.descr,
                "descr_ar": this.data.descr_ar,
                "img": "this.data.img",
            }

            let query = "";

            if(!this.data.id){
                // Add Section
                console.log("insert");
                query = ACADEMY_SERVICES_INSERT    
            } else {
                //edit Section
                query = ACADEMY_SERVICES_UPDATE   
                variables.id = this.data.id
            }
            try{
                const data = await graphQLClient.request(query, variables)
                console.log(JSON.stringify(data, undefined, 2))
                this.data = {};
                if(this.selectedRowToEdit){
                    this.$emit('rowUpdated', data.AcademyServicesUpdate)
                }else{
                    this.$emit('rowInserted', data.AcademyServicesCreate)
                }
                // this.closeForm();
            }catch(err){
                console.log(err);              
            }


        }
    },
    watch: {
        selectedRowToEdit(n){
            console.log(this.selectedRowToEdit + "asaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" +n);
            if(this.selectedRowToEdit != {}){
                this.data.id = this.selectedRowToEdit.id;
                this.data.name = this.selectedRowToEdit.name;
                this.data.name_ar = this.selectedRowToEdit.name_ar;
                this.data.descr = this.selectedRowToEdit.descr;
                this.data.descr_ar = this.selectedRowToEdit.descr_ar;
            }
        }
    }
}
</script>

<style>
.md6{
    display: flex;
}
</style>