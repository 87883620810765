import { gql } from "graphql-request";

export const SERVICES_LIST = gql`
    query {
        AcademyServices{
          name,
          name_ar,
          descr,
          descr_ar,
          id,
          img
        }
    }
`
