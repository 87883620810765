import { gql } from "graphql-request";

export const ACADEMY_SERVICES_INSERT = gql`
    mutation AcademyServicesCreate($name: String!, $name_ar: String!, $descr: String!, $descr_ar: String!, $img: String) {
        AcademyServicesCreate(academy_services: {name: $name, name_ar: $name_ar, descr: $descr, descr_ar: $descr_ar, img: $img}){
            id
            name
            name_ar
            descr
            descr_ar
            img
        }
    }
`
export const ACADEMY_SERVICES_UPDATE = gql`
    mutation AcademyServicesUpdate($id: Int!, $name: String!, $name_ar: String!, $descr: String!, $descr_ar: String!, $img: String) {
        AcademyServicesUpdate(academy_services: {id: $id, name: $name, name_ar: $name_ar, descr: $descr, descr_ar: $descr_ar, img: $img}) {
        id
        name
        name_ar
        descr
        descr_ar
        img
        }
    }
`

export const ACADEMY_SERVICES_DELETE = gql`
    mutation academyServiceDelete($id: Int!){
        AcademyServicesDelete(id: $id)
    }
`
